import type { Clans } from 'lvl-io-sdk'
import { createItemsStore } from '~/utils/pinia/light-items-store'

type Member = Clans.Member & {
  member: Clans.SourceMember
}

export const useClansClanMembersStore = createItemsStore(
  'clans-clan-members',
  {
    getItemsRequests: async () => {
      const { $sdk } = useNuxtApp()
      const {
        members: { idKey, getItems, getMoreItems }
      } = await $sdk.module('clans')

      return {
        idKey,

        getItems: (
          ...[requestQuery, pathOptions, requestOptions]: Parameters<
            typeof getItems<Member>
          >
        ) =>
          getItems<Member>(
            {
              expand: ['member', 'member.styles'],
              sort: '-points_period,created_at',
              ...(requestQuery ?? {})
            },
            pathOptions,
            requestOptions
          ),

        getMoreItems: getMoreItems<Member>
      }
    }
  },
  context => {
    const { $sdk } = useNuxtApp()

    const changeRole = async (
      memberId: Member['member_id'],
      role: Clans.MemberRoleId
    ) => {
      const memberUpdated = await $sdk
        .module('clans')
        .then(({ changeMemberRole }) => changeMemberRole(memberId, role))

      context.updateItem(memberId, memberUpdated)
    }

    const kick = async (memberId: Member['member_id']) => {
      await $sdk.module('clans').then(({ kickMember }) => kickMember(memberId))

      context.removeItem(memberId)
    }

    return { changeRole, kick }
  }
)
